import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import "./_catnav.scss"

const Catnav = () => {
  const data = useStaticQuery(graphql`{
  cat_roesterei: file(relativePath: {eq: "cat_roesterei.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  cat_kaffeebar: file(relativePath: {eq: "cat_kaffeebar.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  cat_laden: file(relativePath: {eq: "cat_laden.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  cat_geschaeftskunden: file(relativePath: {eq: "cat_geschaeftskunden.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <section className="catnav container">
      <div className="catnav-tile">
        <Link to="/roesterei">
          <GatsbyImage
            image={data.cat_roesterei.childImageSharp.gatsbyImageData}
            className="catnav-image"
            alt="unsere rösterei in hildesheim." />
        </Link>
      </div>
      <div className="catnav-tile">
        <Link to="/kaffeebar">
          <GatsbyImage
            image={data.cat_kaffeebar.childImageSharp.gatsbyImageData}
            className="catnav-image"
            alt="unsere kaffeebar in hildesheim." />
        </Link>
      </div>
      <div className="catnav-tile">
        <Link to="/laden">
          <GatsbyImage
            image={data.cat_laden.childImageSharp.gatsbyImageData}
            className="catnav-image"
            alt="unser ladengeschäft in hildesheim." />
        </Link>
      </div>
      <div className="catnav-tile">
        <Link to="/geschaeftskunden">
          <GatsbyImage
            image={data.cat_geschaeftskunden.childImageSharp.gatsbyImageData}
            className="catnav-image"
            alt="geschäftskundenbereich peine" />
        </Link>
      </div>
    </section>
  );
}
export default Catnav
