import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import "./_shopnav.scss"

const Shopnav = () => {
  const data = useStaticQuery(graphql`{
  cat_title_onlineshop: file(relativePath: {eq: "cat_title_onlineshop.png"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  cat_onlineshop1: file(relativePath: {eq: "cat_onlineshop-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  cat_onlineshop2: file(relativePath: {eq: "cat_onlineshop-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  cat_onlineshop3: file(relativePath: {eq: "cat_onlineshop-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <section className="container">
      <div className="shopnav-title">
        <a href="https://shop.daskleineroestwerk.de">
          <GatsbyImage
            image={data.cat_title_onlineshop.childImageSharp.gatsbyImageData}
            className="shopnav-image"
            alt="besuchen sie unseren onlineshop" />
        </a>
      </div>
      <div className="shopnav">
        <div className="shopnav-tile">
          <a href="https://shop.daskleineroestwerk.de">
            <GatsbyImage
              image={data.cat_onlineshop1.childImageSharp.gatsbyImageData}
              className="shopnav-image"
              alt="besuchen sie unseren onlineshop" />
          </a>
        </div>
        <div className="shopnav-tile">
          <a href="https://shop.daskleineroestwerk.de">
            <GatsbyImage
              image={data.cat_onlineshop2.childImageSharp.gatsbyImageData}
              className="shopnav-image"
              alt="alle kaffeesorten online bestellbar" />
          </a>
        </div>
        <div className="shopnav-tile">
          <a href="https://shop.daskleineroestwerk.de">
            <GatsbyImage
              image={data.cat_onlineshop3.childImageSharp.gatsbyImageData}
              className="shopnav-image"
              alt="aktionen und rabatte im onlineshop" />
          </a>
        </div>
      </div>
    </section>
  );
}
export default Shopnav
