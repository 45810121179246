import React, { Component } from "react"

export default class PopUp extends Component {
  handleClick = () => {
    this.props.toggle()
  }

  render() {
    return (
      <div className="modal">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={this.handleClick}>
              &times;
            </span>
          </button>
          <p>liebe gäste,</p>
          <p>
          unsere kaffeebar ist geöffnet
            <br />
            dienstag-samstag 11-18 Uhr
          </p>
          <p>
          und unser ladengeschäft ist geöffnet
            <br />
            dienstag-freitag 11.00 - 17.00 uhr
            <br />
            samstag 10.00 - 16.00 uhr
          </p>
          <p>telefon ladengeschäft 05121 98910-41</p>
          <p>wir freuen uns auf sie/euch</p>
          <p>das team vom kleinen röstwerk</p>
        </div>
      </div>
    )
  }
}
