import React from "react"
import Hero from "../components/hero"
import Catnav from "../components/catnav"
import Shopnav from "../components/shopnav"
import Modal from "../components/modal"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Modal />
    <Hero />
    <section className="container">
      <p>
        am anfang war die faszination…fasziniert von der geschichte des kaffees,
        dem anbau und den unterschiedlichen qualitäten, den verschiedenen
        kaffeebohnen, sowie der vielfalt der zubereitung und der möglichkeiten
        haben wir uns schon fast unser ganzes leben lang mit kaffee beschäftigt.
        um mehr zu erfahren haben wir kaffeeanbauländer besucht und direkte
        kontakte zu besonderen kaffeeplantagen hergestellt. bereichert durch
        diese erfahrungen haben wir unsere eigenen röstungen verfeinert,
        besondere röstprofile entwickelt und unsere unverwechselbaren
        lieblingskaffees hervorgebracht. die können sie bei uns kaufen –
        entscheiden sie, welcher ihr liebling ist…
      </p>
      <p>wir sind das kleine röstwerk – erfahrt mehr über uns</p>
    </section>    
    <Shopnav />
    <Catnav />
  </Layout>  
)

export default IndexPage
